export default function About() {
    return (
        <div id="about" className="about-section">
            <h1 className="about-head">↬ About me !</h1>
            {/* <h1 className="about-head-alt">↬ मेरे बारे में !</h1> */}
            <div className="about-sub">
                <h3 className="about-sub-text">
                    Hey I am Yash, a student at Indian Institute of Technology Madras. Currently, I am in freshman year of B.S. Data Science and Applications.
                    Following an interest in development, deployment and operations for software and learning various concepts of 
                    the data industry. I'm now looking ahead to build a career in the coalesced area of DevOps and MLOps.
                    
                    {/* Hello, I am a 17 year old student from India with passion for Frontend and Software development.
                    With deep understanding of <span className="about-lang">HTML, CSS and Javascript</span>, I build responsive websites that work on
                    small as well as large screens. My interests also includes <span className="about-role">Software development and Data Science.</span>  */}
                </h3>
            </div>
        </div>
    )
}