export default[
    {
        name: "Azure",
        logo: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/azure/azure-original.svg"
    },
    {
        name: "JavaScript",
        logo: "https://raw.githubusercontent.com/devicons/devicon/master/icons/javascript/javascript-original.svg",
    },
    {
        name: "HTML",
        logo: "https://raw.githubusercontent.com/devicons/devicon/master/icons/html5/html5-original.svg"
    },
    {
        name: "ReactJS",
        logo : "https://raw.githubusercontent.com/devicons/devicon/master/icons/react/react-original.svg"
    },
    {
        name: "CSS",
        logo: "https://raw.githubusercontent.com/devicons/devicon/master/icons/css3/css3-original.svg"
    },
    {
        name: "Python",
        logo: "https://raw.githubusercontent.com/devicons/devicon/master/icons/python/python-original.svg"
    },
    {
        name: "Arduino",
        logo : "https://raw.githubusercontent.com/devicons/devicon/master/icons/arduino/arduino-original.svg"
    },
    {
        name: "Flutter",
        logo : "https://raw.githubusercontent.com/devicons/devicon/master/icons/flutter/flutter-original.svg"
    },
    {
        name: "Java",
        logo: "https://raw.githubusercontent.com/devicons/devicon/master/icons/java/java-original.svg"
    },
    {
        name: "Dart",
        logo: "https://raw.githubusercontent.com/devicons/devicon/master/icons/dart/dart-original.svg"
    },
    {
        name: "Git",
        logo: "https://raw.githubusercontent.com/devicons/devicon/master/icons/git/git-original.svg"
    },
    {
        name: "Bulma",
        logo: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/bulma/bulma-plain.svg"
    },
    {
        name: "Figma",
        logo: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/figma/figma-original.svg"
    }
]