export default function Contact() {
    return (
        <div id="contact" className="contact">
            <div className="social">
                <a href="https://www.linkedin.com/in/yashkc2025/" className="fa fa-linkedin "></a>
                <a href="https://github.com/yashkc2025" className="fa fa-github-alt"></a>
                <a href="mailto:yashkc2025@gmail.com" className="fa fa-envelope"></a>
                <a href="https://leetcode.com/betaal/"><img src="https://upload.wikimedia.org/wikipedia/commons/a/ab/LeetCode_logo_white_no_text.svg"></img></a>
                <a href="https://angel.co/u/yashkc2025" className="fa fa-angellist"></a>
                <a href="https://t.me/Yashkc2025" className="fa fa-telegram"></a>
            </div>
            <div className="contact-form">
                <button ><a href="mailto:yashkc2025@gmail.com?subject=Hi&body=Hey Yash, Just wanted to say hi ^_^">Drop a Message »</a></button>
            </div>
        </div>
    )
}