export default [
    {
        name: "Blogs",
        logo: "https://raw.githubusercontent.com/yashkc2025/yashkc2025.github.io/images/blogs.png",
        desc: "A blog website made using Astro JS with support for markdown. It also has a dark mode.",
        link: "https://yashkc2025.pages.dev/",
        trylink: "https://yashkc2025.pages.dev/",
        downlink: "",
        tools: ["#Astro", "#Markdown", "#HTML", "#CSS", "#JavaScript"],
    },
    {
        name: "Gui Calculator",
        logo: "https://repository-images.githubusercontent.com/534926619/2310d639-8977-4337-83ac-8f96d61c49b2",
        desc: `A complete graphical app to perform mathematical functions. This app has been designed with Flutter and Dart.`,
        link: "https://github.com/yashkc2025/gui_calculator",
        trylink: "https://yashkc2025.github.io/gui_calculator/",
        downlink: "https://github.com/yashkc2025/gui_calculator/releases/download/add_func/Calculator.apk",
        tools: ["#dart", "#flutter", "#android", "#c++"],
    },
    {
        name: "Shortier",
        logo: "https://addons.mozilla.org/user-media/previews/full/217/217631.png?modified=1622132759",
        desc: "A Link shortener with support for various providers. Written in Python. Run directly from Terminal/CMD",
        link: "https://github.com/yashkc2025/shortier",
        trylink: "",
        downlink: "",
        tools:  ["#python"],
    },
    {
        name: "Login Screen",
        logo: "https://repository-images.githubusercontent.com/534338049/93a8ed37-e1dc-48d6-83c2-4c8939a4daa3",
        desc: "A pink coloured login screen made using Flutter and Dart. It provides the ability to login as well as registering a user.",
        link: "https://github.com/yashkc2025/login-grey",
        trylink: "",
        downlink: "",
        tools: ["#dart", "#flutter", "#android", "#c++"],
    },
    {
        name: "Samosa Inc",
        logo: "https://raw.githubusercontent.com/yashkc2025/yashkc2025.github.io/main/assets/samosa.png",
        desc: "A website to order Samosa directly to your home",
        trylink: "https://yashkc2025.github.io/Samosa-Inc/",
        downlink: "",
        tools: ["#html", "#css", "#javascript", "#react"],
        
    },
    {
        name: "Meme Generator",
        logo: "https://repository-images.githubusercontent.com/549565410/6d4223cc-f6c5-4df5-bfc0-362df8cb146b",
        desc: "A meme generator that let's you design your own memes. Just enter text and click generate meme.",
        link: "https://github.com/yashkc2025/meme-gen",
        trylink: "",
        downlink: "",
        tools: ["#html", "#css", "#javascript", "#react"],
    },
    // {
    //     name: "BMI-app",
    //     logo: "https://img.freepik.com/premium-vector/body-mass-index-weight-control-with-bmi-gauge_664444-1.jpg?w=2000",
    //     desc: "Wanna check your BMI ? This will soon become part of the calculator app.",
    //     link: "https://github.com/yashkc2025/bmi-app",
    //     trylink: "https://yashkc2025.github.io/bmi-app/",
    //     downlink: "https://github.com/yashkc2025/bmi-app/releases/download/1/bmi_app.1.apk",
    //     tools: ["https://raw.githubusercontent.com/devicons/devicon/master/icons/dart/dart-original.svg",
    //     "https://cdn.icon-icons.com/icons2/2107/PNG/512/file_type_gradle_icon_130565.png",    
    //     "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/android/android-plain.svg",
    //         "https://raw.githubusercontent.com/devicons/devicon/master/icons/flutter/flutter-original.svg",
    //         "https://raw.githubusercontent.com/devicons/devicon/master/icons/cplusplus/cplusplus-original.svg"],

    // },
    {
        name: "Veggies App",
        logo: "https://raw.githubusercontent.com/yashkc2025/yashkc2025.github.io/main/assets/Web%20capture_13-3-2023_183551_localhost.jpeg",
        desc: "Simple illustration of a veggie ordering app",
        trylink: "",
        downlink: "",
        tools: ["#html", "#css", "#javascript", "#react"],

    }
]
